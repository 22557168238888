import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
//import { Container, Row, Col } from 'reactstrap';
import LandingPage from './components/LandingPage';
import StoryPage from './components/StoryPage';
import Chat from './components/Chat';  
import ChatAssistant from './components/ChatAssistant';  
import LimerickGenerator from './components/LimerickGenerator';
import MessageForm from './components/MessageForm';
//import AddNewUser from './components/AddNewUser';
//import Login from './components/Login';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chatassistant" element={<ChatAssistant />} />
        <Route path="/story" element={<StoryPage />} />
        <Route path="/limerick" element={<LimerickGenerator />} />
        <Route path="/messageform" element={<MessageForm />} />
      </Routes>
    </Router>
  );
};

export default App;