import React, { useState } from 'react';
import axios from 'axios';
//import dotenv from 'dotenv';
//dotenv.config();

const apiPath = process.env.REACT_APP_API_PATH;

function MessageForm () {
    const [message, setMessage] = useState('');

    const sendMessage = () => {
        axios.post(`${apiPath}/sendmessage`, { message })
            .then(response => {
                console.log("Response: ",response);
            })
            .catch(error => {
                console.log("Error: ",error);
            });
    };

    return (
        <div>
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

export default MessageForm;