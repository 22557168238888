import React, { useState } from 'react';
import axios from 'axios';

const LimerickGenerator = () => {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [city, setCity] = useState('');
  const [fact, setFact] = useState('');
  const [limerick, setLimerick] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('/api/generate-limerick', {
        name,
        age,
        city,
        fact,
      });
      setLimerick(response.data.limerick);
    } catch (error) {
      console.error('Error:', error);
    }

    setLoading(false);
  };

  return (
    <div>
      <h2>Limerick Generator</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Child's Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="number"
          placeholder="Age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
        <input
          type="text"
          placeholder="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <input
          type="text"
          placeholder="Really Fun Fact"
          value={fact}
          onChange={(e) => setFact(e.target.value)}
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Generating...' : 'Generate Limerick'}
        </button>
      </form>
      {limerick && (
        <div>
          <h3>Generated Limerick:</h3>
          <p>{limerick}</p>
        </div>
      )}
    </div>
  );
};

export default LimerickGenerator;