import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

function ChatAssistant() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const ws = useRef(null);
  const childName = sessionStorage.getItem('childName');
  const [showNextButton, setShowNextButton] = useState(false);
  
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const wsUrl = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.hostname + ':5001/';
    console.log('WebSocket URL:',wsUrl);
    ws.current = new WebSocket(wsUrl);
    //ws.current = new WebSocket('ws://localhost:5001/');
    console.log('WebSocket connection opened');
    
    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
      sendMessage(`Let's write a story for ${childName}`);
    };
    
    ws.current.onerror = (error) => {
    console.error('WebSocket error:', error);
    };
    
    ws.current.onclose = () => {
    console.log('WebSocket connection closed');
    };

    ws.current.onmessage = (event) => {
      const message = event.data;
      let chatRole;
      let chatMessage;
      try {
        //console.log('Received message:',message);
        const parsedMessage = JSON.parse(message);
        //console.log('Successfully parsed JSON.',parsedMessage);
        if (parsedMessage.type === 'init') {
          console.log('Init...');
          sessionStorage.setItem('threadId', parsedMessage.payload.threadId);
          console.log('Received threadId and stored as client session variable:',parsedMessage.payload.threadId);
        } else if (parsedMessage.type === 'chat') {
          //console.log('Chat message...');
          chatRole = parsedMessage.payload.role;
          chatMessage = parsedMessage.payload.message;
          console.log('Message from ',chatRole,':',chatMessage);
        }
      } catch (error) {
        console.log('Error parsing JSON:',error);
      }
      setMessages((prevMessages) => {
        let newMessages = [...prevMessages];
        if (newMessages.length > 0 && newMessages[newMessages.length - 1].role === 'assistant') {
            newMessages[newMessages.length - 1] = { role: 'assistant', content: chatMessage };
          } else {
            newMessages.push({ role: 'assistant', content: chatMessage });
          }
        return newMessages;
      });
      if (chatMessage === "Okay, let's write our story.") {
        setShowNextButton(true);
      }
    };

    scrollToBottom();

    return () => ws.current.close();

  }, [childName]);

  const sendMessage = (messageContent) => {
    ws.current.send(JSON.stringify({type: 'chat', payload:{ role: 'user', content: messageContent }}));
    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: messageContent }]);
    setInput('');
  };



  const handleNext = () => {
    navigate("/story");
  };

  return (
    <div className="container">
      <h1>Storysmith</h1>
      <div className="chat-box">
        {messages.map((msg, index) => (
          <div key={index}
            className={msg.role === 'user' ? 'user-message' : 'assistant-message'}
            ref={index === messages.length - 1 ? messagesEndRef : null}
          >
            {msg.content}
          </div>
        ))}
        <div className="input-container">
          <input className="text-input" type="text" value={input} onChange={(e) => setInput(e.target.value)} onKeyPress={(event) => event.key === 'Enter' ? sendMessage(input) : null}/>
          <button className="submit-button" onClick={() => sendMessage(input)}>Send</button>
          <button 
            className={`next-button ${showNextButton ? 'show' : ''}`} 
            onClick={handleNext} 
            disabled={!showNextButton}
          />
      </div>
      </div>
    </div>
  );
}

export default ChatAssistant;