import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const [childName, setChildName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem('childName', childName);
    navigate('/chatassistant');
  };

  return (
    <div className="container">
      <h1>Storysmith</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="childName">Let's write a story for</label>
          <input
            type="text"
            className="form-control"
            id="childName"
            autoFocus
            value={childName}
            onChange={(e) => setChildName(e.target.value)}
            required
          />
          <button type="submit" className="btn btn-primary"></button>
        </div>
      </form>
    </div>
  );
};

export default LandingPage;